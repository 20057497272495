<template>
  <div>
    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>

    <div>
      <vs-divider class="mt-base">Pembayaran</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Rencana Bayar</label>
          <vs-input class="w-full" type="text" v-model="data.nama_cara_bayar" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Bank</label>
          <vs-input class="w-full" type="text" v-model="data.nama_bank_kpr" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">COA Debet</label>
          <vs-input class="w-full" type="text" v-model="data.nama_coa_debet" readonly/>
        </div>
      </div>

      <vs-divider class="mt-24">Harga Unit</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">HPP Tanah</label>
          <v-money class="w-full" v-model="data.total_hpp_tanah" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">HPP Bangunan</label>
          <v-money class="w-full" v-model="data.total_hpp_bangunan" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Total HPP</label>
          <v-money class="w-full" :value="(data.total_hpp_tanah + data.total_hpp_bangunan)" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Harga Standar</label>
          <v-money class="w-full" v-model="data.harga_standar" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Diskon</label>
            <vs-input class="w-full" :value="`${idr(data.diskon_jml)} (${idr(data.diskon_persen)}%)`" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Harga Standar (Setelah Diskon)</label>
            <v-money class="w-full" :value="data.harga_standar - data.diskon_jml" readonly/>
          </div>
      </div>

      <vs-divider class="mt-24">Biaya Unit</vs-divider>
      <div class="vx-row mb-1 mt-3">
        <div class="vx-col sm:w-12/12 w-full">
          <vs-table :data="listBiayaUnit" stripe class="fix-table-input-background">
            <template slot="thead">
              <vs-th class="whitespace-no-wrap">#</vs-th>
              <vs-th class="whitespace-no-wrap">Jadikan Termin</vs-th>
              <vs-th class="whitespace-no-wrap">Tipe</vs-th>
              <vs-th class="whitespace-no-wrap">Deskripsi</vs-th>
              <vs-th class="whitespace-no-wrap">Biaya</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                <vs-td class="w-8">{{ index + 1 }}</vs-td>
                <vs-td class="w-40 text-center">
                  <feather-icon v-if="item.is_as_termin" icon="CheckIcon" svgClasses="text-success w-5 h-5"/>
                </vs-td>
                <vs-td class="w-64">{{ item.type }}</vs-td>
                <vs-td style="width: 22rem;">{{ item.description }}</vs-td>
                <vs-td>{{ idr(item.amount) }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-8/12 w-full">
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Total Biaya Unit</label>
          <v-money class="w-full" :value="data.total_biaya_unit" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-8/12 w-full">
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs block">Total Harga Unit<span class="opacity-50 inline-block">(Harga Standar Setelah Diskon + Total Biaya Unit)</span></label>
          <v-money class="w-full" :value="data.total_harga_unit" readonly/>
        </div>
      </div>

      <vs-divider class="mt-24">Pajak</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">DPP</label>
          <v-money class="w-full" v-model="data.dpp" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">PPN</label>
          <vs-input class="w-full" :value="`${idr(data.ppn_jml)} (${data.ppn_persen}%)`" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">PPh Jenis</label>
          <vs-input class="w-full" type="text" v-model="data.pph_nama" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">PPh</label>
          <vs-input class="w-full" :value="`${idr(data.pph_jml)} (${data.pph_persen}%)`" readonly/>
        </div>
      </div>

      <vs-divider class="mt-24">Biaya Tambahan</vs-divider>
      <div class="vx-row mb-1 mt-3">
        <div class="vx-col sm:w-12/12 w-full">
          <vs-table :data="listBiayaTambahan" stripe class="fix-table-input-background">
            <template slot="thead">
              <vs-th class="whitespace-no-wrap">#</vs-th>
              <vs-th class="whitespace-no-wrap">Jadikan Termin</vs-th>
              <vs-th class="whitespace-no-wrap">Tipe</vs-th>
              <vs-th class="whitespace-no-wrap">Deskripsi</vs-th>
              <vs-th class="whitespace-no-wrap">Biaya</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                <vs-td class="w-8">{{ index + 1 }}</vs-td>
                <vs-td class="w-40 text-center">
                  <feather-icon v-if="item.is_as_termin" icon="CheckIcon" svgClasses="text-success w-5 h-5"/>
                </vs-td>
                <vs-td class="w-64">{{ item.type }}</vs-td>
                <vs-td style="width: 22rem;">{{ item.description }}</vs-td>
                <vs-td>{{ idr(item.amount) }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-8/12 w-full">
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Total Biaya Tambahan</label>
          <v-money class="w-full" :value="data.total_biaya_tambahan" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-8/12 w-full">
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs block"><span class="text-warning block">Total Harga Jual</span><span class="opacity-50 inline-block">(DPP + PPN + Total Biaya Tambahan)</span></label>
          <v-money class="w-full" :value="data.total_harga_jual" readonly/>
        </div>
      </div>

      <vs-divider class="mt-24">Uang Muka</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Termin Uang Muka ( X )</label>
          <vs-input class="w-full" :value="data.termin_dp" readonly/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Total Uang Muka</label>
          <vs-input class="w-full" :value="`${idr(data.dp_jml)} (${data.dp_persen}%)`" readonly/>
        </div>
      </div>

      <vs-divider class="mt-24">Angsuran</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Sisa Angsuran</label>
          <v-money class="w-full" :value="data.angsuran_jml" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Termin Angsuran ( X )</label>
          <vs-input class="w-full" :value="data.termin_angsuran" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Tgl. Mulai Jatuh Tempo</label>
          <vs-input class="w-full" :value="data.angsuran_start_due" readonly/>
        </div>
      </div>

      <vs-divider class="mt-24">Review Termin</vs-divider>
      <vs-table :data="bookingAngsurans" stripe class="fix-table-input-background">
        <template slot="thead" class="text-sm">
          <vs-th class="whitespace-no-wrap">#</vs-th>
          <vs-th class="whitespace-no-wrap">Termin Ke</vs-th>
          <vs-th class="whitespace-no-wrap">Tipe</vs-th>
          <vs-th class="whitespace-no-wrap text-right">Persen Bayar</vs-th>
          <vs-th class="whitespace-no-wrap text-right">Jumlah Bayar</vs-th>
          <vs-th class="whitespace-no-wrap text-right">DPP</vs-th>
          <vs-th class="whitespace-no-wrap text-right">PPN</vs-th>
          <vs-th class="whitespace-no-wrap text-right">PPh</vs-th>
          <vs-th class="whitespace-no-wrap text-right">Terbayar</vs-th>
          <vs-th class="whitespace-no-wrap">Due Date</vs-th>
          <vs-th class="whitespace-no-wrap text-right">Keterangan</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td><span class="font-bold" :class="['text-' + getAngsuranColor(item)]">Ke - {{ item.no_termin }}</span></vs-td>
            <vs-td>
              <span class="inline-flex items-center whitespace-no-wrap justify-center p-2 text-xs leading-none text-white rounded-full" :class="['bg-' + getAngsuranColor(item)]">
                {{ item.tipe_angsuran }}
              </span>
            </vs-td>
            <vs-td class="text-right">{{ item.persen | idr(2) }}%</vs-td>
            <vs-td class="text-right">{{ item.jml_nominal | idr }}</vs-td>
            <vs-td class="text-right">{{ item.dpp | idr }}</vs-td>
            <vs-td class="text-right">{{ item.jml_ppn | idr }}</vs-td>
            <vs-td class="text-right">{{ item.jml_pph | idr }}</vs-td>
            <vs-td class="text-right">{{ item.jml_bayar | idr }}</vs-td>
            <vs-td class="text-center whitespace-no-wrap">{{ item.tgl_due }}</vs-td>
            <vs-td>{{ item.keterangan }}</vs-td>
          </vs-tr>
          <!--footer-->
          <vs-tr v-if="bookingAngsurans.length > 0" class="text-sm">
            <vs-td class="font-bold">Total</vs-td>
            <vs-td class="text-right font-bold" colspan="3" >{{ summary.totalPercent | idr(2) }} %</vs-td>
            <vs-td class="text-right font-bold">{{ summary.totalNominal | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ summary.totalDpp | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ summary.totalPpn | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ summary.totalPph | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ summary.totalBayar | idr }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

  </div>
</template>

<script>
import BookingRepository from '@/repositories/marketing/booking-repository'
import VMoney from '@/views/components/v-money/VMoney.vue'
import KontrakAngsuranRepository from '@/repositories/marketing/kontrak/kontrak-angsuran-repository'
import _ from 'lodash'
import colorMixin from '@/views/pages/marketing/booking/mixins/tab-angsuran/colorMixin'

export default {
  name: 'TabAngsuran',
  props: ['isActive'],
  mixins: [colorMixin],
  components: {
    VMoney
  },
  watch: {
    isActive (value) {
      if (value && !this.isDataInited) {
        this.initData()
      }
    }
  },
  computed: {
    listBiayaUnit () {
      return this.data.list_biaya.filter(item => item.group === 'BIAYA UNIT')
    },
    listBiayaTambahan () {
      return this.data.list_biaya.filter(item => item.group === 'BIAYA TAMBAHAN')
    },
    summary () {
      return {
        totalPercent: _.sumBy(this.bookingAngsurans, item => item.persen),
        totalNominal: _.sumBy(this.bookingAngsurans, item => item.jml_nominal),
        totalDpp: _.sumBy(this.bookingAngsurans, item => item.dpp),
        totalPpn: _.sumBy(this.bookingAngsurans, item => item.jml_ppn),
        totalPph: _.sumBy(this.bookingAngsurans, item => item.jml_pph),
        totalBayar: _.sumBy(this.bookingAngsurans, item => item.jml_bayar)
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      bookingAngsurans: [],
      data: {
        list_biaya: []
      }
    }
  },
  methods: {
    initData () {
      this.getBooking()
      this.isDataInited = true
    },

    getBooking () {
      this.isLoading = true

      const idBooking = this.$route.params.idBooking
      BookingRepository.show(idBooking)
        .then(response => {
          this.data = response.data.data
          this.getBookingAngsuran(idBooking)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    getBookingAngsuran (idBooking) {
      KontrakAngsuranRepository.get(idBooking)
        .then(response => {
          this.bookingAngsurans = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    }
  }
}
</script>
